<template>
  <div>
    <v-data-table :items="items" :headers="headers">
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon @click="addWeights(item)">mdi-weight</v-icon>
      </template>
      <template v-slot:[`item.animals`]="{ item }">
        <div class="animalList">
          <div v-for="externalId in item.animals" :key="externalId">
            {{ externalId }}
          </div>
        </div>
      </template>
    </v-data-table>
    <WeightDialog :show="showWeightDialog" :item="selectedItem" @reload="getItems" @input="showWeightDialog = $event" />
  </div>
</template>

<script>
import WeightDialog from './WeightDialog'

export default {
  name: 'BillOfLading',

  components: {
    WeightDialog
  },

  data: () => ({
    items: [],
    headers: [
      {
        text: 'Date',
        align: 'start',
        sortable: true,
        value: 'date'
      },
      { text: 'Animals', value: 'animals', sortable: false },
      { text: 'Weight', value: 'totalWeight', sortable: false },
      { text: 'Actions', value: 'actions', sortable: false }
    ],
    showWeightDialog: false,
    selectedItem: {}
  }),

  beforeMount () {
    this.getItems()
  },

  methods: {
    getItems () {
      fetch('/api/bills-of-lading', {
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.items = data
        })
    },

    addWeights (item) {
      this.selectedItem = item
      this.showWeightDialog = true
    }

  }

}
</script>

<style scoped>
.animalList {
  display: grid;
  grid-auto-columns: 15em;
  grid-template-columns: repeat(4, 1fr);
  align-items: start;
}
</style>
