<template>
  <v-dialog v-model="showDialog">
    <v-card>
      <v-card-title class="headline">Add weights</v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field v-model="weight" label="Total weight (kg)" type="number" required></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondar" text @click="showDialog = false">Cancel</v-btn>
        <v-btn color="primary" @click="save">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'WeightDialog',

  props: {
    show: {
      type: Boolean,
      required: true
    },
    item: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    weight: 0
  }),

  computed: {
    showDialog: {
      get () {
        return this.show
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },

  methods: {
    save () {
      fetch(`/api/bills-of-lading/${this.item.id}`, {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          totalWeight: this.weight
        })
      })
        .then((response) => {
          if (response.status === 200) {
            this.showDialog = false
            this.$emit('reload')
          }
        })
    }
  }
}
</script>
